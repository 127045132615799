(function(sr){
  // debouncing function from John Hann
  // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
  var debounce = function (func, threshold, execAsap) {
    var timeout;

    return function debounced () {
      var obj = this, args = arguments;
      function delayed () {
        if (!execAsap) {
          func.apply(obj, args);
        }
        timeout = null;
      }

      if (timeout) {
        clearTimeout(timeout);
      }
      else if (execAsap) {
        func.apply(obj, args);
      }

      timeout = setTimeout(delayed, threshold || 100);
    };
  };
  // smartresize
  $.fn[sr] = function(fn){ return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };
})('smartresize');

(function ($) {
  'use strict';

  // Flexslider
  $(function () {
    $('.flexslider').flexslider({
      animationLoop: false,
      directionNav: false
    });
  });

  // Mobile toggle
  $('.js-mobile-toggle__link').click(function(e) {
    e.preventDefault();

    var $self = $(this);
    $self.toggleClass('is-active');
    $self.find('.mobile-toggle__link__icon').toggleClass('open');
    $('.primary-nav').slideToggle();
  });

  // selectBoxIt
  $(function () {
    var selectAgeBox = $('.js-form-select-age').selectBoxIt({
      autoWidth: false
    });

    var selectPeriodBox = $('.js-form-select-period').selectBoxIt({
      autoWidth: false
    });

    selectAgeBox.on('changed', function() {
      // Add changed class when value is changed
      $('.js-form-select-age').addClass('selectBox-changed');

      // Only enable period box when age is selected
      selectPeriodBox.data('selectBox-selectBoxIt').enable();
    });

    selectPeriodBox.on('changed', function() {
      // Add changed class when value is changed
      $('.js-form-select-period').addClass('selectBox-changed');
    });
  });

  // Tabs
  var tabs = $('.js-tabs');
  tabs.find('.tabs__list__item:first-child a').addClass('is-current');
  tabs.find('.tabs__content__item:first-child').addClass('show');

  $(function () {
    $('.js-tab-link').on('click', function(e) {
      e.preventDefault();
      var $self = $(this);

      tabs.find('.tabs__list__item__link').removeClass('is-current');
      $self.toggleClass('is-current');
      tabs.find('.tabs__content__item').removeClass('show');
      tabs.find('.tabs__content__item:nth-child(' + ($(this).closest('.tabs__list__item').index() + 1) + ')').addClass('show');
    });

    if(window.location.hash) {
      var hash = window.location.hash;
      $('.js-tabs a[href$="' + hash + '"]').trigger('click');
    }
  });

  $('.js-tabbedcontent').tabbedContent({
    currentClass: 'is-current'
  });

  // Accordion
  $(function () {
    $('.js-accordion').on('click', '.js-accordion-link', function(e) {
      e.preventDefault();
      var $self = $(this);
      $self.toggleClass('is-current');
      $self.closest('.accordion__item').find('.accordion__item__content').slideToggle();
    });
  });

  // News
  $(function() {
    $('.js-news-toggle').click(function(e) {
      e.preventDefault();
      var newsItem = $(this).closest('.news-item');
      newsItem.toggleClass('is-open');
      newsItem.find('.news-item__content__body').slideToggle();
      newsItem.find('.news-item__content__actions a').fadeToggle();
    });
  });

  // Smooth-scroll
  smoothScroll.init();

  // Horizontal scroller
  (function () {
    var $frame  = $('.js-gallery-slider__sly'),
        sliderMiddleItem = Math.floor($frame.find('ul li').length / 2);

    $frame.imagesLoaded( function() {
      // Call Sly on frame
      $frame.sly({
        horizontal: 1,
        itemNav: 'centered',
        speed: 300,
        startAt: sliderMiddleItem,
        mouseDragging: 1,
        touchDragging: 1,
        elasticBounds: 1,
        releaseSwing: 1
      });

      $frame.sly('one', 'moveStart', function() {
        $frame.closest('.gallery-slider').removeClass('is-init');
      });
    });

    // Reload (re-calculate) sly scroller when resizing
    $(window).smartresize(function() {
      $frame.sly('reload');
    });

  }());
})(window.jQuery);
